import React from "react";
import { Heading } from "../../components/Heading";
import { Link } from "../../components/Link";
import { ImportCircle } from "../../icons/ImportCircle";
import { TwitterFill } from "../../icons/TwitterFill";
import "./style.css";
import React, { useState } from 'react';


export const Desktop = () => {

  const [email, setEmail] = useState('');
  const [successText, setSuccessText] = useState('');
  const [errorText, setErrorText] = useState('');
  const [warningText, setWarningText] = useState('');
  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const emailChange = (e) => {
    setEmail(e);
    document.querySelector('.warning-text').style.display = 'none';
  }

  const handleClick = () => {
    if (!validateEmail(email)) {
      setWarningText("Please enter a valid email address.");
      document.querySelector('.warning-text').style.display = 'block';
      return;
    }

    fetch('https://cryptopika.4bade3.com/submit-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
    .then(response => response.json())
    .then(data => {

      // if the response status code is not 200, throw an error
      if (data.status !== 200) {
        setErrorText(data.detail);
        document.querySelector('.error-text').style.display = 'block';
        document.querySelector('.field').style.display = 'none';
        document.querySelector('.email-button').style.display = 'none';
      } else {
        console.log('Success:', data);
        // get the details from the response and set the success-text to the details
        setSuccessText(data.detail);
        // document.querySelector('.success-text').innerHTML = data.detail;
        console.log(data.detail)
        // Show the success-text and hide the input field
        document.querySelector('.success-text').style.display = 'block';
        document.querySelector('.field').style.display = 'none';
        document.querySelector('.email-button').style.display = 'none';
      }
    })
    .catch((error) => {
      console.log("Already registered for whitelist")
      setErrorText(error.detail);
      document.querySelector('.error-text').style.display = 'block';
      document.querySelector('.field').style.display = 'none';
      document.querySelector('.email-button').style.display = 'none';
    });
  }


  return (
    <div className="desktop">
      <div className="div-2">
      <div className="glowy-line-section-header">
          <div className="glowy-line" />
          <div className="glowy-line-2" />
        </div>
        <div className="nav-bar">

          <div className="frame2-left">
            <img className="cryptopika-logo" alt="CryptoPika Logo" src="/img/Union.png" />
          </div>
          <div className="frame-2">
            <TwitterFill className="twitter-fill" />
            <a className="text-wrapper-5" href="#services">Service</a>
            <a className="text-wrapper-5" href="#about-us">About Us</a>
            {/* a logo on the left side */}
          </div>
        </div>
        
        <div className="overlap-group">
          <div className="landing-page">
            {/* <div className="navigation-bar" /> */}
            <header className="header">
              <div className="frame">
              <img className="crypto-pika" alt="Crypto pika" src="/img/cryptopika.png" />
                <p className="text-wrapper-3">The decentralized prompt-to-video generative AI model operating on blockchain</p>
                <p className="success-text">{successText}</p>
                <p className="warning-text">{warningText}</p>
                <p className="error-text">{errorText}</p>
                <input className="field" placeholder="Email" type="email" value={email} onChange={e => emailChange(e.target.value)} />
                <div className="group">
                  <div className="overlap-group-2">
                    <button className="email-button" onClick={handleClick}>Sign up for Whitelist</button>
                  </div>
                </div>
              </div>
              {/* <img className="screenshot" alt="Screenshot" src="/img/screenshot-2023-12-12-at-22-06-1.png" /> */}
              {/* a autoplay video with repeat without any video tooltips */}
              {/* <video className="screenshot" autoPlay loop muted> */}
                {/* <source src="/img/Morty_00027.mp4" type="video/mp4" /> */}
                {/* </video> */}
                <div class="content">
                  <video 
                    class="hero-video-actual" 
                    autoPlay 
                    loop 
                    muted 
                    playsinline 
                    src="/img/Morty_00027.mp4" 
                    type="video/mp4">
                  </video>
                  
                  
                  
                  {/* <video id="player" src="/img/Morty_00027.mp4" autoPlay loop muted playsinline></video> */}
                </div>
            </header>
            <ImportCircle className="import-circle" />
          </div>
        </div>
        <div className="about-us-section" id="about-us">
          <button className="about-us-button">About Us</button>
          <p className="p">CryptoPika is a decentralized prompt-to-video generative AI model operating on blockchain</p>
          <div className="flex-spacer" />
        </div>
        <div className="case-studies-block">
          <div className="case-studies">
            <div className="case-study">
              <p className="div-3">
                <span className="span">
                  Decentralized <br />
                  Computing Power
                  <br />
                </span>
                <span className="text-wrapper-6">
                  <br />
                  <br />
                  Millions of blockchain miners around the world will be able to provide computing power to CryptoPika 24/7
                </span>
              </p>
              <Link className="link-instance" hasDiv={false} hasIcon={false} property1="simple-green" />
            </div>
            <img className="line" alt="Line" src="/img/line-4.svg" />
            <div className="case-study">
              <p className="div-3">
                <span className="span">
                  Decentralized <br />
                  Data Storage
                  <br />
                </span>
                <span className="text-wrapper-7">
                  <br />
                  <br />
                  All user data, prompt inputs, and output images are permanently inscribed in a decentralized manner on blockchain
                </span>
              </p>
            </div>
            <img className="line" alt="Line" src="/img/line-4.svg" />
            <div className="case-study">
              <p className="decentralized">
                <span className="span">
                  Decentralized <br />
                  Ownership <br />
                </span>
                <span className="text-wrapper-6">
                  <br />
                  <br />
                  Unlike Pika or other centralized platforms who own everything their users create, CryptoPika empower users to truly own their creation
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="services-section-2" id="services">
          <div className="image-container">
            <div className="rectangle-left"></div>
            <div className="rectangle-text">
              <button className="services-button">Services</button>
              <p className="service-p"> CryptoPika will serve over <span className="service-number">100,000</span> crypto projects, helping them establish their projects’ anime-style image, generate promotional videos, and use these videos to illustrate and showcase the key information about the project. </p>
              {/* <img className="cryptopika-will" alt="Cryptopika will"
              src="/img/cryptopika-will-serve-over-100-000-crypto-projects-helping-them.png"/> */}
              
            </div>
            <div className="rectangle-right"></div>
          </div>
        </div>

        <div className="glowy-line-section">
          <div className="glowy-line" />
          <div className="glowy-line-2" />
        </div>

        <div className="footer">
          <img className="footer-image-logo" alt="Crypto pika" src="/img/Union.png" />
          <img className="footer-image" alt="Crypto pika" src="/img/cryptopika-1.png" />
          {/* Copyright ©2023 CryptoPika. All rights reserved. */}
          <p className="p-footer">©2023 CryptoPika. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};
